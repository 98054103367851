<template>
  <div style="background: #F5F5F5;">
    <div class="messageContent w">
      <div class="crumbs">
        <router-link to="/">首页</router-link>
        <span>/</span>
        留言互动
        <span>/</span>
        <span style="color: #07C160">建言献策</span>
      </div>
      <div class="buttonM">
        <div @click="goMessage">
          我要留言&nbsp;
          <img src="../assets/message.png"/>
        </div>
      </div>
      <div class="messageList">
        <div class="messageItem" v-for="item in messageList">
          <div class="messageTitle">
            <div>{{ item.subject }}</div>
            <div>{{ formartTime(item.createTime) }}</div>
          </div>
          <div class="message">{{ item.body }}</div>
          <div class="reply">
            <div class="triangle"></div>
            <span style="color: #07C160;">回复:</span>
            {{ item.reply }}
          </div>
          <div>
          </div>
        </div>
        <div class="pagebox">
          <el-pagination
            layout="prev, pager, next"
            :page-size="12"
            :current-page="pageNo"
            @current-change="pageChange"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref, onMounted} from 'vue'
import {getAction} from '../api'
import {useRoute,useRouter} from 'vue-router'
import dayjs from 'dayjs'
import {ElPagination} from 'element3'

export default defineComponent({
  name: 'Industry',
  components: {ElPagination},
  setup() {
    const route = useRoute()
    const router = useRouter()
    const messageList = ref([])
    const pageNo = ref(1)
    const total = ref(0)

    const formartTime = (val) => {
      return dayjs(val).format('YYYY/MM/DD')
    }
    const getMesssageList = () => {
      getAction('/yncy/interactive/leaveMessage/list', {pageSize: 10, pageNo: pageNo.value}).then(res => {
        messageList.value = res.result.records
        total.value = res.result.total
      })
    }
    const goMessage = () => {
      router.push('/messageBox')
    }
    onMounted(() => {
      getMesssageList()
    })

    return {messageList, pageNo, total, formartTime, goMessage}
  }
})
</script>

<style lang="scss" scoped>
/deep/ .el-pager li.active {
  background: #07C160;
  border-radius: 50%;
  color: #ffffff;
  padding: 0;
}

.messageContent {
  background: #FFFFFF;

  .pagebox {
    margin-top: 20px;
  }

  .buttonM {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;

    div {
      width: 258px;
      height: 38px;
      text-align: center;
      color: #00BB3D;
      border: 1px solid #00BB3D;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
      cursor: pointer;
    }
  }

  .messageList {
    padding: 0 20px;

    .messageItem {
      margin-top: 48px;

      .messageTitle {
        display: flex;
        justify-content: space-between;

        div:first-child {
          height: 24px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 24px;
        }

        div:last-child {
          height: 20px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.45);
          line-height: 20px;
        }
      }

      .message {
        margin-top: 8px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
      }

      .reply {
        margin-top: 12px;
        background: #F5F5F5;
        padding: 10px 5px;
        position: relative;
        border-radius: 5px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.65);
        line-height: 24px;

        .triangle {
          position: absolute;
          top: -10px;
          left: 10px;
          width: 0;
          height: 0;
          border-bottom: 10px solid #F5F5F5;
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
        }
      }


    }
  }
}
</style>
