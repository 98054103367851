<template>
  <div class="w">
    <div class="crumbs">
      <router-link to="/">首页</router-link>
      <span>/</span>
      新闻通告
      <span>/</span>
      <em>{{title }}</em>
    </div>
    <div class="types">
<!--      <div :class="{active: newsCategory === 1}" @click="changeCategory(1)">通知公告</div>-->
      <div :class="{active: newsCategory === 2}" @click="changeCategory(2)">要闻快讯</div>
      <div :class="{active: newsCategory === 3}" @click="changeCategory(3)">行业动态</div>
    </div>
    <ul class="list">
      <li v-for="item of list" :key="item.id">
        <router-link :to="{path: 'news', query: {id: item.id, type: newsCategory, title:newsCategory===1 ? '通知公告':newsCategory===2?'要闻快讯':'行业动态'}}">
          <span><i></i>{{item.subject}}</span>
          <div>
            {{formartTime(item.createTime)}}
          </div>
        </router-link>
      </li>
    </ul>
    <div class="pagebox">
      <el-pagination
        layout="prev, pager, next"
        :page-size="12"
        :current-page="pageNo"
        @current-change="pageChange"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { ElPagination } from 'element3'
import { getAction } from '../api'
import { useRoute, useRouter } from 'vue-router'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'List',
  components: {
    ElPagination
  },
  watch: {
    '$route.query': function (val) {
      this.newsCategory = Number(val.type) || 1
      this.pageNo = 1
      this.getListData()
    }
  },
  setup () {
    const list = ref([])
    const pageNo = ref(1)
    const total = ref(0)
    const newsCategory = ref(0)

    const route = useRoute()
    const router = useRouter()
    const url = ref(null)
    const title = ref('')

    const getListData = () => {
      if (newsCategory.value === 1) {
        url.value = '/yncy/notice/yncyNotice/list'
        title.value = '通知公告'
      } else if (newsCategory.value === 2) {
        url.value = '/yncy/news/yncyNews/list'
        title.value = '要闻快讯'
      } else if (newsCategory.value === 3) {
        url.value = '/yncy/industrynews/yncyIndustryNews/list'
        title.value = '行业动态'
      }
      getAction(url.value, { pageNo: pageNo.value, pageSize: 12,auditStatus:1 }).then(res=>{
        total.value = res.result.total
        list.value = res.result.records
      })
      // if (newsCategory.value === 3) {
      //   industryNews({ pageNo: pageNo.value, pageSize: 12 }).then(res => {
      //     total.value = res.result.total
      //     list.value = res.result.records
      //   })
      // } else {
      //   yncyNewsList({ newsCategory: newsCategory.value, pageNo: pageNo.value, pageSize: 12 }).then(res => {
      //     total.value = res.result.total
      //     list.value = res.result.records
      //   })
      // }
    }
    onMounted(() => {
      if (route.query.type) {
        newsCategory.value = Number(route.query.type)
      }

      getListData()
    })

    const pageChange = (page) => {
      pageNo.value = page
      getListData()
    }

    const changeCategory = (type) => {
      newsCategory.value = type
      router.push({ query: { type } })
      pageNo.value = 1
      getListData()
    }

    const formartTime = (val) => {
      return dayjs(val).format('YYYY/MM/DD')
    }


    return { list, pageNo, total,title, pageChange, changeCategory, newsCategory, getListData, formartTime }
  }
})
</script>

<style>
.el-pager li{
  min-width: 28px;
}
.el-pager li.active{
  background: #07C160;
  border-radius: 50%;
  color: #ffffff;
  padding:0;
}
</style>
<style lang="scss" scoped>
.types{
  border-bottom: 1px solid #dddddd;
  margin-top: 30px;
  display: flex;
  margin-bottom: 30px;

  &>div{
    flex: 1;
    text-align: center;
    line-height: 50px;
    font-size: 22px;
    cursor: pointer;

    &.active{
      color: #07C160;
      border-bottom: 1px solid #07C160;
    }
  }
}
.list{
  margin-bottom: 50px;
  border-bottom: 1px solid #DDDDDD;

  li{
    width: 1152px;
    margin: 0 auto;
    border-bottom: 1px dotted #DDDDDD;
    line-height: 55px;

    &:last-child{
      border-bottom: 0;
    }
  }

  a{
    display: flex;justify-content: space-between; align-items: center;padding: 0 8px;
    &:hover > div, &:hover > span{
      color:#07C160;
    }
  }
  span{
    display: block;
    align-items: center;
    width: 900px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }
  i{
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 8px;
    background: #333333;
  }
}
</style>
