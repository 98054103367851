<template>
  <div style="background: #F5F5F5;">
    <div class="messageContent w">
      <div class="crumbs">
        <router-link to="/">首页</router-link>
        <span>/</span>
        留言互动
        <span>/</span>
        <span style="color: #07C160">建言献策</span>
      </div>
      <div class="msg">
        <div>留言须知:</div>
        <div class="msgtxt">
          <div class="triangle"></div>
          欢迎您使用公众留言系统，请就国家林业和草原局业务范围内的工作提出建议、意见和问题，留言前请先阅读以下条款：<br/>
          一、遵守中华人民共和国宪法和法律以及相关管理规定。<br/>
          二、本栏目只用于国家林业和草原局和公众之间的交流，不用于公众互相之间的交流，请勿包含任何举报方面的信息。<br/>
          三、提问时请填写真实姓名、身份证号、电话、电子邮件信箱等信息，我们将依法保护您的个人隐私；您的联系方式如不真实、不准确，系统将不予接受，无法受理。<br/>
          四、留言者承担一切因留言行为而直接或间接引起的法律责任,留言内容的公布不表示本栏目同意留言者所表达的立场和观点；<br/>
          五、本栏目拥有发布、保留、删除公众提问的权利；六、凡在本栏目留言，即表示已阅读并接受上述各项条款。
        </div>
      </div>
      <div class="form">
        <div class="item">
          <el-input type="number" v-model="tel" placeholder="请输入您的手机号"></el-input>
          <span class="item-icon">*</span>
        </div>
        <div class="item">
          <el-input v-model="name" placeholder="请输入您的昵称"></el-input>
          <span class="item-icon">*</span>
        </div>
        <div class="item">
          <el-input v-model="title" placeholder="留言标题"></el-input>
          <span class="item-icon">*</span>
        </div>
        <div class="item">
          <el-input
            type="textarea"
            placeholder="请详细输入留言内容"
            v-model="content"
            maxlength="200"
            show-word-limit
          />
        </div>
        <el-button type="success" @click="submit">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref, onMounted} from 'vue'
import {postAction} from '../api'
import {useRoute, useRouter} from 'vue-router'
import dayjs from 'dayjs'
import {ElPagination} from 'element3'
import {ElMessage} from 'element-plus'

export default defineComponent({
  name: 'Industry',
  components: {ElPagination},
  setup() {
    const route = useRoute()
    const tel = ref(null)
    const name = ref('')
    const title = ref('')
    const content = ref('')
    const router = useRouter()
    const submit = () => {
      if (!tel.value) {
        ElMessage({
          message: '请输入手机号',
          type: 'warning'
        })
      } else if (!name.value) {
        ElMessage({
          message: '请输入昵称',
          type: 'warning'
        })
      } else if (!title.value) {
        ElMessage({
          message: '请输入留言标题',
          type: 'warning'
        })
      } else if (!(/^1[3456789]\d{9}$/.test(tel.value))) {
        ElMessage({
          message: '手机号格式有误',
          type: 'warning'
        })
      } else {
        postAction('/yncy/interactive/leaveMessage/add', {
          phone: tel.value,
          penName: name.value,
          subject: title.value,
          body: content.value
        }).then(res => {
          ElMessage({
            message: '留言成功',
            type: 'success'
          })
          router.push('/messageBoard')
        })
      }
    }
    onMounted(() => {

    })

    return {tel, name, title, content, submit}
  }
})
</script>

<style lang="scss" scoped>
.messageContent {
  background: #FFFFFF;
  mar: 20px;

  .msg {
    padding: 48px 32px 0 32px;

    .msgtxt {
      margin-top: 12px;
      background: #ccf1d8;
      padding: 10px 5px;
      position: relative;
      border-radius: 5px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 30px;

      .triangle {
        position: absolute;
        top: -10px;
        left: 10px;
        width: 0;
        height: 0;
        border-bottom: 10px solid #ccf1d8;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
      }

    }
  }

  .form {
    width: 828px;
    margin: 72px auto 88px auto;

    /deep/ .el-button--success {
      width: 100%;
      margin-top: 16px;
      color: #FFFFFF !important;
    }

    .item {
      margin-top: 10px;
      position: relative;

      /deep/ .el-textarea__inner {
        height: 80px;
      }

      .item-icon {
        position: absolute;
        color: red;
        right: 10px;
        top: 16px;
      }
    }
  }
}
</style>
