<template>
  <div class="w">
    <div class="crumbs">
      <router-link to="/">首页</router-link>
      <span>/</span>
<!--      留言互动-->
<!--      <span>/</span>-->
      <span style="color: #07C160">意见征集</span>
    </div>
    <div class="navBar">
      <div class="left">意见征集</div>
      <div class="right">
        <div :class="{active: !newsCategory}" @click="changeCategory(0)">全部</div>
        <div :class="{active: newsCategory==1}" @click="changeCategory(1)">征求</div>
        <div :class="{active: newsCategory==2}" @click="changeCategory(2)">结果</div>
      </div>
    </div>
    <ul class="list">
      <li v-for="item of list" :key="item.id">
        <router-link :to="{path: 'grassDetial', query: {id: item.id, type: '意见征集'}}">
          <span><i></i>{{item.type==1?'【征求】':'【结果】'}}{{item.subject}}</span>
          <div>
            {{formartTime(item.createTime)}}
          </div>
        </router-link>
      </li>
    </ul>
    <div class="pagebox">
      <el-pagination
        layout="prev, pager, next"
        :page-size="12"
        :current-page="pageNo"
        @current-change="pageChange"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { ElPagination } from 'element3'
import { opinionList } from '../api'
import { useRoute, useRouter } from 'vue-router'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'List',
  components: {
    ElPagination
  },
  watch: {
    // this.$route.path
    '$route.query': function (val) {
      this.newsCategory = Number(val.type) || 0
      this.pageNo = 1
      this.getListData()
    }
  },
  setup () {
    const list = ref([])
    const pageNo = ref(1)
    const total = ref(0)
    const newsCategory = ref('')

    const route = useRoute()
    const router = useRouter()

    const getListData = () => {
      opinionList({ pageNo: pageNo.value, pageSize: 12, type: newsCategory.value === 0 ? '' : newsCategory.value }).then(res => {
        console.log(res)
        total.value = res.result.total
        list.value = res.result.records
      })
    }
    onMounted(() => {
      getListData()
    })

    const pageChange = (page) => {
      pageNo.value = page
      getListData()
    }

    const changeCategory = (type) => {
      newsCategory.value = type
      pageNo.value = 1
      getListData()
    }

    const formartTime = (val) => {
      return dayjs(val).format('YYYY/MM/DD')
    }

    return { list, pageNo,route, total, pageChange, changeCategory, newsCategory, getListData, formartTime }
  }
})
</script>

<style>
.el-pager li{
  min-width: 28px;
}
.el-pager li.active{
  background: #07C160;
  border-radius: 50%;
  color: #ffffff;
  padding:0;
}
</style>
<style lang="scss" scoped>
.navBar{
  margin-top: 20px;
  border-bottom: 1px solid #dddddd;
  display: flex;
  justify-content: space-between;
  .left{
    line-height: 50px;
    font-size: 22px;
  }
  .right{
    display: flex;
    &>div{
      width: 80px;
      line-height: 50px;
      font-size: 22px;
      cursor: pointer;
      &.active{
        color: #07C160;
      }
    }
  }
}
.types{
  border-bottom: 1px solid #dddddd;
  margin-top: 30px;
  display: flex;
  margin-bottom: 30px;

  &>div{
    flex: 1;
    text-align: center;
    line-height: 50px;
    font-size: 22px;
    cursor: pointer;

    &.active{
      color: #07C160;
      border-bottom: 1px solid #07C160;
    }
  }
}
.list{
  margin-bottom: 50px;
  border-bottom: 1px solid #DDDDDD;

  li{
    width: 1152px;
    margin: 0 auto;
    border-bottom: 1px dotted #DDDDDD;
    line-height: 55px;

    &:last-child{
      border-bottom: 0;
    }
  }

  a{
    display: flex;justify-content: space-between; align-items: center;padding: 0 8px;
    &:hover > div, &:hover > span{
      color:#07C160;
    }
  }
  span{
    display: block;
    align-items: center;
    width: 900px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }
  i{
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 8px;
    background: #333333;
  }
}
</style>
